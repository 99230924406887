import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Router from 'vue-router';
import 'amfe-flexible';
import hedaer from "../src/components/header.vue";
import Footer from "../src/components/footer.vue";
import "animate.css"
import "wowjs/css/libs/animate.css"
import wow from 'wowjs'

Vue.prototype.$wow = wow
Vue.component('hedaer', hedaer)
Vue.component('Footer', Footer)
Vue.config.productionTip = false

Vue.prototype.$url = 'https://static.drlianzhuren.com/brandWeb/'


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
