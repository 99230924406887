<template>
  <div>
    <div class="hedaer-top">
      <img src="https://static.drlianzhuren.com/brandWeb//logo.png" alt="" />
      <div class="hedaer-tab" @click="show = true">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="header">
      <div
        class="banner"
        :style="{ backgroundImage: 'url(' + imgList[selectTab].img + ')' }"
        v-if="!isInfo"
      >
        <div v-show="!imgList[selectTab].text">
          <div
            class="banner-title wow fadeInUp"
            v-for="(item, index) in imgList[selectTab].title"
            :key="index"
          >
            {{ item }}
          </div>
        </div>

        <div class="banner-text wow fadeInUp" v-show="imgList[selectTab].text">
          {{ imgList[selectTab].text }}
        </div>
        <div class="banner-En wow fadeInUp">
          {{ imgList[selectTab].en }}
        </div>
      </div>
      <poup v-if="show" @setItem="setItem" @setShow="setShow"></poup>
    </div>
  </div>
</template>
  
  <script>
import poup from "../components/headerProp.vue";
export default {
  components: { poup },
  data() {
    return {
      wow: null,
      show: false,
      isInfo: false,
      select: 1,
      selectTab: 0,
      imgList: [
        {
          img: require("../assets/banner/1.jpg"),
          title: ["技术创造美好", "品质引领未来"],
          en: "Technology creates beauty , Quality leads the future",
        },
        {
          img: require("../assets/banner/2.jpg"),
          title: ["植发不剃发", "就到碧莲盛"],
          en: "Lotus No Haircut Transplant",
        },
        {
          img: require("../assets/banner/3.jpg"),
          text: "新闻动态",
          en: "Press Release",
        },
        {
          img: require("../assets/banner/4.jpg"),
          text: "社会责任",
          en: "Epidemic public welfare",
        },
        {
          img: require("../assets/banner/5.jpg"),
          text: "投资者关系",
          en: "Investor relations",
        },
        {
          img: require("../assets/banner/6.jpg"),
          text: "业务模块",
          en: "Business",
        },
        {
          img: require("../assets/banner/7.jpg"),
          text: "联系我们",
          en: "Contact us",
        },
      ],
    };
  },
  beforeDestroy() {
    this.wow.stop();
  },
  mounted() {
    switch (this.$route.path) {
      case "/home":
        this.selectTab = 0;
        break;
      case "/aboutUs":
        this.selectTab = 1;
        break;
      case "/journalism":
        this.selectTab = 2;
        break;
      case "/info":
        this.isInfo = true;
        break;
      case "/responsibility":
        this.selectTab = 3;
        break;
      case "/Investor":
        this.selectTab = 4;
        break;
      case "/business":
        this.selectTab = 5;
        break;
      case "/contactUs":
        this.selectTab = 6;
        break;
    }
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
  },
  methods: {
    setItem(a) {
      this.select = a;
      this.$emit("setSelect", this.select);
    },
    setShow() {
      this.show = false;
    },
  },
};
</script>
  
  <style lang="scss">
.hedaer-top {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 75px;

  background-color: #2e59a7;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 90px;
  }
  .hedaer-tab {
    width: 28px;
    height: 22px;
    div {
      height: 2px;
      background-color: #ffffff;
    }
    div:nth-child(2) {
      margin: 8px 0;
    }
  }
}
.header {
  padding-top: 75px;
  .banner {
    width: 100%;
    height: 540px;
    background: url("../assets/banner/1.jpg") no-repeat;
    background-size: 100% 100%;
    padding-top: 70px;
    .banner-text {
      margin-top: 150px;
      text-align: center;
      font-size: 45px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 1px;
      color: #ffffff;
    }
    .banner-title {
      margin: 0 auto;
      width: 280px;
      text-align: center;
      font-size: 45px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 55px;
      letter-spacing: 1px;
      color: #ffffff;
    }
    .banner-En {
      text-align: center;
      margin-top: 20px;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 23px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}
</style>