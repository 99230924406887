<template>
    <div id="app">
        <keep-alive include="journalism">
            <router-view />
        </keep-alive>
    </div>
</template>
<script>
export default {
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    // if (this._isMobile()) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
     if (vw <= 900) {
      console.log("手机端");
    } else {
      console.log("pc端");
      location.href = "https://www.blsgroup.cn/home";
    }
  },
};
</script>
<style  lang="scss">
@font-face {
    font-family: "wryh";
    /* 重命名字体名 */
    src: url("./assets/family/msyh.ttf");
    font-weight: normal;
    font-style: normal;
}

html {
    font-family: wryh;
}

div,
ul,
li,
span,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1;
}

li {
    list-style-type: none;
}

.Profile-title {
    text-align: center;
    margin-top: 47px;
    font-size: 35px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #363636;
}

.Profile-en {
    text-align: center;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #707070;
    margin-top: 14px;
    margin-bottom: 30px;
}

.home-tab {
    display: flex;
    justify-content: space-between;
    padding: 0 33px;

    li {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #363636;
        padding-bottom: 13px;
    }

    .selectBab {
        font-weight: bold;
        position: relative;
    }

    .selectBab::after {
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translate(-50%);
        content: "";
        display: inline-block;
        width: 34px;
        height: 2px;
        background-color: #315fb4;
    }
}

.item {
    padding: 40px 20px;
}

.home-line {
    width: 100%;
    height: 1px;
    border-top: solid 1px #b8b8b8;
}

.Profile-text {
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #707070;
    margin-top: 24px;
}

.LoveHelpStudents-text {
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #707070;
}

.LoveHelpStudents-img {
    width: 500px;
    height: 327px;
    border-radius: 8px;
}
</style>
