<template>
  <div class="footer">
    <div class="footer-code">
      <div
        @click="
          show = true;
          isCode = 1;
        "
      >
        微信公众号
      </div>
      <div
        @click="
          show = true;
          isCode = 2;
        "
      >
        官方小程序
      </div>
    </div>
    <div class="footer-phone">
      <div class="footer-circular">
        <i class="iconfont icon-24gf-telephone"></i>
      </div>
      联系电话 400-888-9882
    </div>
    <div class="footer-phone">
      <div class="footer-circular">
        <i class="iconfont icon-dingweixiao"></i>
      </div>
      北京市 大兴区 西红门鸿坤广场B座3号楼5层
    </div>
    <div class="footer-line"></div>
    <div
      style="display: flex; margin: 0.5rem 0 0 0; justify-content: center"
    >
      <div style="margin-right: .4rem">
        <img
          src="../assets/footer/2.jpg"
          alt=""
          style="width: 1.3rem; height: 0.95rem"
        />
        <div
          style="
            color: #ffffff;
            opacity: 0.6;
            font-size: 0.3rem;
            margin-top: 0.25rem;
            text-align: center;
          "
        >
          营业执照
        </div>
      </div>
      <div>
        <img
          src="../assets/footer/1.jpg"
          alt=""
          style="width: 1.3rem; height: 0.95rem"
        />
        <div
          style="
            color: #ffffff;
            opacity: 0.6;
            font-size: 0.3rem;
            margin-top: 0.25rem;
            text-align: center;
          "
        >
          许可证
        </div>
      </div>
    </div>
    <div class="footer-record">北京碧莲盛医疗美容门诊部有限责任公司</div>
    <div class="footer-recordT">
      <a style="color: inherit" href="http://beian.miit.gov.cn" target="_blank"
        >京ICP备12001479号-54</a
      >
      <img
        :src="`${$url}/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png`"
        alt=""
        class="footerFiling"
      />
      <a
        target="_blank"
        style="color: inherit"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502006011"
      >
        京公网安备 11011502006011号</a
      >
    </div>
    <Code v-if="show" :isCode="isCode" @setShow="show = false"></Code>
  </div>
</template>

<script>
import Code from "../components/code.vue";
export default {
  components: {
    Code,
  },
  data() {
    return {
      isCode: 1,
      show: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.footer {
  background-color: #2e59a7;
  padding: 20px 20px 50px;

  .footer-code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 40px;
    div {
      width: 200px;
      height: 43px;
      line-height: 43px;
      text-align: center;
      margin-top: 20px;
      border-radius: 5px;
      border: solid 1px #97acd3;
      color: #97acd3;
      font-size: 18px;
    }
  }
  .footer-phone {
    color: #97acd3;
    font-size: 18px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    .footer-circular {
      width: 21px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      i {
        font-size: 13px;
      }
      border: 1px solid #97acd3;
      border-radius: 50%;
    }
  }
  .footer-line {
    height: 1px;
    width: 100%;
    background: #97acd3;
  }
  .footer-record {
    margin: 18px auto 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #97acd3;
    text-align: center;
  }
  .footer-recordT {
    margin: 0 auto 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #97acd3;
    text-align: center;
  }
}
.footer-next {
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  padding: 15px 0;
  margin-left: 27px;
  border-bottom: solid 1px #97acd3;
  letter-spacing: 0px;
  color: #97acd3;
}

@keyframes Culturetab {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 200px;
  }
}
.tab-animation {
  overflow: hidden;
  animation: Culturetab 0.3s linear both;
}
@keyframes reverseAni {
  0% {
    max-height: 150px;
  }

  100% {
    max-height: 0;
  }
}
.reverse-animation {
  overflow: hidden;
  animation: reverseAni 0s linear both;
}
a {
  text-decoration: none;
}
.footerFiling {
  width: 16px;
  margin-bottom: -2px;
  margin-left: 4px;
}
</style>
