<template>
  <div class="mark">
    <div class="alert">
      <div class="mark-Code">
        <div class="error" @click="$emit('setShow')"></div>
        <template v-if="isCode == 1">
          <img
            src="https://static.drlianzhuren.com/brandWeb/contactUs/wx.png"
            alt=""
            class="mark-img"
          />
          <div class="mark-text">微信公众号</div>
        </template>
        <template v-else>
          <img
            src="https://static.drlianzhuren.com/brandWeb//xcxCode.jpg"
            alt=""
            class="mark-img"
          />
          <div class="mark-text">官方小程序</div>
        </template>
      </div>
    </div>
  </div>
</template>
    
    
    
    <script>
export default {
  props: {
    isCode: {
      type: Number,
      default: 0,
    },
  },
};
</script>
    
    <style scoped lang="scss">
.mark {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .alert {
    position: relative;
  }

  .error {
    width: 19px;
    height: 19px;
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0px;
  }

  .error::before,
  .error::after {
    content: "";
    position: absolute;
    height: 30px;
    width: 2px;
    top: 20px;
    right: 30px;
    background: #707070;
  }

  .error::before {
    transform: rotate(45deg);
  }

  .error::after {
    transform: rotate(-45deg);
  }
}
.mark-Code {
  width: 443px;
  height: 480px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
}
.mark-img {
  width: 250px;
  height: 250px;

  margin-top: 100px;
}
.mark-text {
  text-align: center;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  margin-top: 20px;
  letter-spacing: 0px;
  color: #707070;
}
</style>